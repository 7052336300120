.container {
  padding: 20px 3%;
}

.section {
  padding: 100px 0px;
}

.postList {
  border-left: 0.5px solid #1c2541;
  margin-bottom: 30px;
  width: 90vw;
  max-width: 950px;
  margin: 30px 0px;
}

.postBtn {
  display: block;
  background-color: inherit;
  color: #f7f5fb;
  padding: 15px;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  border-left: 3px solid transparent;
  font-size: clamp(17px, 4vw, 19px);
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  font-weight: 100;
  font-family: "Fira Code";
  width: 100%;
}

.postBtn:focus-visible {
  background-color: #80ffdb;
  color: #1c2541;
}

/* Change background color of buttons on hover */
.postBtn:hover {
  color: #80ffdb;
  background-color: #1c2541;
}

.activeBtn {
  color: #80ffdb;
  border-left: 3px solid #80ffdb;
  background-color: #1c2541;
}

.postContainer {
  margin: 20px 0px;
  max-width: 950px;
}
