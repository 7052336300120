.container {
	padding: 20px 3%;
}

.grid {
	display: grid;
	grid-template-columns: 94vw;
	margin: 40px 0;
	gap: 2%;
}

.grid:nth-of-type(even) {
	direction: rtl;
}

/* Style the tab */
.tab {
	border-left: 0.5px solid #1c2541;
	margin-bottom: 30px;
}

/* Style the buttons inside the tab */
.tablinks {
	display: block;
	background-color: inherit;
	color: #f7f5fb;
	padding: 15px;
	border: none;
	outline: none;
	text-align: left;
	cursor: pointer;
	transition: 0.3s;
	border-left: 3px solid transparent;
	font-size: clamp(17px, 4vw, 19px);
	text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	margin: 5px 0;
	font-weight: 100;
	font-family: "Fira Code";
	width: 100%;
}

.tablinks:focus-visible {
	background-color: #80ffdb;
	color: #1c2541;
}

/* Change background color of buttons on hover */
.tablinks:hover {
	color: #80ffdb;
	background-color: #1c2541;
}

.activeTab {
	color: #80ffdb;
	border-left: 3px solid #80ffdb;
	background-color: #1c2541;
}

/* Style the tab content */
.tabcontent {
	padding: 0 20px;
	text-align: left;
	direction: ltr;
}

.right {
	text-align: right;
	margin-top: 30px;
}

.row {
	display: flex;
	gap: 10px;
	align-items: center;
}

@media screen and (min-width: 700px) {
	.grid {
		grid-template-columns: 200px 50vw;
	}
}

@media screen and (min-width: 900px) {
	.grid {
		grid-template-columns: 300px 50vw;
	}
}
