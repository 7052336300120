/* BUTTON */
.btn {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  font-size: clamp(18px, 2vw, 20px);
  color: #f7f5fb;
  border: 0.5px solid #80ffdb;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;
}

.btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #80ffdb;
  transform: translateX(-100%);
  transition: all 0.3s;
  z-index: -1;
}

.btn:hover::before {
  transform: translateX(0);
}

.btn:hover {
  color: #1c2541;
}

/* ICON BUTTON */
.iconBtn {
  display: inline-block;
  height: 35px;
  width: 35px;
  fill: #80ffdb;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;
  padding: 4px;
}

.iconBtn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #80ffdb;
  transform: translateX(-100%);
  transition: all 0.3s;
  z-index: -1;
}

.iconBtn:hover::before {
  transform: translateX(0);
}

.iconBtn:hover {
  fill: #1c2541;
}

.listBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.listBtn svg {
  visibility: hidden;
  transform: rotate(90deg);
  margin-left: 7px;
}

button.listBtn:hover svg {
  visibility: visible;
}

button.listBtn:focus-visible svg {
  visibility: visible;
}

.pl {
  padding-left: 10px;
}
