.container {
	padding: 20px 3%;
	max-width: 1100px;
	margin-bottom: 150px;
}

.grid {
	display: grid;
	gap: 70px;
}

.contentGrid {
	display: grid;
	gap: 20px;
	position: relative;
}

.links {
	display: flex;
	gap: 15px;
}

.tags {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	margin: 20px 0 10px 0;
	justify-content: flex-start;
	direction: ltr;
}

.tags > h6 {
	font-size: clamp(14px, 2vw, 16px);
	background-color: #80ffdb;
	border-radius: 5px;
	padding: 5px 10px;
	color: #1c2541;
	margin: 0;
}

@media screen and (min-width: 900px) {
	.contentGrid {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (min-width: 1200px) {
	.contentGrid {
		grid-template-columns: 2fr 1fr;
	}
}

.contentGrid:nth-of-type(even) {
	direction: rtl;
}

.description {
	padding: 10px 20px;
	border-left: 3px solid #80ffdb;
	background-color: #1c2541;
	margin: 15px 0;
	text-align: left;
}

.images {
	position: relative;
	display: flexbox;
	margin: auto;
}

.bugbytes1 {
	content: url("../../images/HOME.png");
	position: relative;
	max-width: 250px;
	width: 100%;
	box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
	z-index: 3;
	margin-top: 50px;
	filter: grayscale(70%);
}

.bugbytes2 {
	content: url("../../images/CONTACT\ US.png");
	position: relative;
	max-width: 250px;
	width: 100%;
	box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
	z-index: 2;
	margin-top: -330px;
	margin-left: 70px;
	filter: grayscale(70%);
}

.bugbytes3 {
	content: url("../../images/BLOG.png");
	position: relative;
	max-width: 250px;
	width: 100%;
	box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
	z-index: 1;
	margin-top: -20px;
	margin-left: 100px;
	filter: grayscale(70%);
}

.vsw {
	content: url("../../images/vsw.png");
	max-width: 500px;
	width: 100%;
	filter: grayscale(70%);
}

.vsw:hover {
	filter: grayscale(0%);
	transition: all 0.2s ease-in;
	transform: scale(1.05);
}

.photo1 {
	content: url("../../images/Editing.png");
	position: relative;
	max-width: 250px;
	width: 100%;
	box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
	z-index: 2;
	margin-top: 0px;
	filter: grayscale(70%);
}

.photo2 {
	content: url("../../images/About.png");
	position: relative;
	max-width: 250px;
	width: 100%;
	box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
	z-index: 1;
	margin-top: -100px;
	margin-left: 70px;
	filter: grayscale(70%);
}

.active {
	z-index: 4 !important;
	filter: grayscale(0%);
	transition: all 0.2s ease-in;
	transform: scale(1.4);
}
