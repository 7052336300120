@import url("https://fonts.googleapis.com/css?family=Plaster&display=swap");

.nav {
  display: flex;
  justify-content: space-between;
  padding: 10px 5%;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  background: linear-gradient(#020c1b, #020c1b00);
  backdrop-filter: blur(6px);
  box-shadow: 10px 10px 20px #020c1b5c;
  transition: 0.3s linear;
  z-index: 100;
}

.hideNav {
  top: -80px;
  transition: 0.3s linear;
}

.nav > a {
  text-decoration: none;
}

.logo {
  font-family: "Plaster";
  font-size: 30px;
  color: #80ffdb;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  letter-spacing: -0.05em;
  transition: 0.3s;
}

.navIcons {
  display: flex;
  gap: 10px;
}

.active > button {
  fill: #1c2541;
  background-color: #80ffdb;
}

.logo:hover {
  color: #f7f5fb;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transform: translate(0, -8px);
}

/* .tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltipText {
	font-family: "Montserrat";
	font-size: 16px;
	visibility: hidden;
	width: 85px;
	color: #f7f5fb;
	text-align: right;
	border-radius: 7px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	top: 113%;
	right: 3%;
	opacity: 0;
	transition: opacity 0.5s;
}

.tooltip:hover .tooltipText {
	visibility: visible;
	opacity: 1;
} */
