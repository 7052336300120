body {
  padding: 0;
  margin: 0;
  font-family: "Raleway", "Montserrat", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f7f5fb;
  font-weight: 300;
  min-height: 100vh;
  background-attachment: fixed;
  background-color: #020c1b;
  background-size: fill;
  background-position: center center;
  background-image: radial-gradient(at 43% 19%, #0a192f 0px, transparent 50%),
    radial-gradient(at 73% 4%, #0a192f 0px, transparent 50%),
    radial-gradient(at 0% 6%, #0a192f 0px, transparent 50%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-weight: bold;
  letter-spacing: 0.1rem;
  max-height: 300px;
}

p {
  margin: 0.7rem 0;
  color: #b5bfdf;
  font-size: clamp(15px, 4vw, 17px);
}

h1,
h2,
h3,
h4 {
  font-family: "Playfair Display";
  font-weight: 900;
}

h5,
h6,
button,
a {
  font-family: "Fira Code";
  color: #80ffdb;
  text-decoration: none;
}

h1 {
  font-size: clamp(40px, 10vw, 60px);
  color: #f7f5fb;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  margin: 0;
}

h2 {
  font-size: clamp(40px, 7vw, 60px);
  color: #b5bfdf;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  margin: 0;
  line-height: clamp(35px, 7vw, 50px);
}

h3 {
  font-size: clamp(27px, 6vw, 35px);
  color: #b5bfdf;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  margin: 0;
  line-height: clamp(25px, 7vw, 50px);
}

h5 {
  font-size: clamp(17px, 4vw, 19px);
  color: #80ffdb;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  margin: 5px 0;
  font-weight: 100;
}

h6 {
  font-size: clamp(15px, 4vw, 17px);
  color: #80ffdb;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  margin: 10px 0 20px 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1c2541;
}

::-webkit-scrollbar-corner {
  background: #1c2541;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b5bfdf;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #80ffdb;
}

ul {
  color: #b5bfdf;
  font-size: clamp(15px, 4vw, 17px);
  list-style-image: url("./icons/arrow-right-1.svg");
  display: grid;
  gap: 5px 10px;
}

li {
  margin: 5px 0;
}

hr {
  border: 0;
  height: 5px;
  background-image: linear-gradient(
    to right,
    rgba(28, 37, 65, 0),
    rgba(28, 37, 65, 0.75),
    rgba(28, 37, 65, 0)
  );
}
