.container {
  padding: 20px 3%;
}

.text {
  max-width: 660px;
  margin-top: 30px;
}

.skills {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 10px;
}

b.shine {
  font-family: "Montserrat";
  font-weight: 500;
  background: linear-gradient(
    to right,
    #f7f5fb 10%,
    #80ffdb 30%,
    #80ffdb 60%,
    #f7f5fb 90%
  );
  background-size: 200% auto;
  color: #80ffdb;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@media screen and (min-width: 1000px) {
  .skills {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
