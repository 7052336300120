.container {
  margin: 30px 0px;
  text-align: justify;
  max-width: 90vw;
}

.showMoreBtn {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #f7f5fb;
  border: 0.5px solid #80ffdb;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;
}

.showMoreBtn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #80ffdb;
  transform: translateX(-100%);
  transition: all 0.3s;
  z-index: -1;
}

.showMoreBtn:hover::before {
  transform: translateX(0);
}

.showMoreBtn:hover {
  color: #1c2541;
}

.center {
  margin-top: 40px;
  text-align: center;
}

ol button {
  color: #b5bfdf;
  font-size: clamp(15px, 4vw, 17px);
  border: none;
  background-color: transparent;
  font-family: Montserrat;
  font-weight: 300;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
}

ol li::marker {
  color: #80ffdb;
}

ol button:hover {
  color: #80ffdb;
  background-color: #1c2541;
}

.code {
  background-color: #1c2541;
  padding: 0 5px;
  border-radius: 3px;
  font-family: "Fira Code";
}

img {
  border-radius: 5px;
  max-height: 300px;
  max-width: 80%;
  object-fit: contain;
  margin: 10px 0;
}

div:has(> img) {
  text-align: center;
}

.inlineImages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.inline {
  /* display: flex; */
  justify-content: space-between;
  text-align: left;
}

@media screen and (min-width: 800px) {
  .inlineImages {
    flex-direction: row;
  }

  img {
    max-width: 50%;
  }
}

button:has(svg) {
  border: none;
  cursor: pointer;
}

button:has(svg):focus-visible {
  outline: 5px auto #80ffdb !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: 3px;
}
